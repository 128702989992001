.carousel {
  position: relative;
  perspective: 500px;
  transform-style: preserve-3d;
}

.card-container {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: 
    rotateY(calc(var(--offset) * 50deg))
    scaleY(calc(1 + var(--abs-offset) * -0.3))
    translateZ(calc(var(--abs-offset) * -165px))
    translateX(calc(var(--direction) * -14px));
  transition: all 0.3s ease-out;
}

.card {
  background-color: hsl(0deg, 0%, calc(100% - 0.5*var(--abs-offset) * 7.5%));
  border-radius: 1rem;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 8px;
  transition: all 0.3s ease-out;
}

.card h2 {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin: 0 0 0.7em;
  color: #1f2937;
}

.card p,
.card h2 {
  transition: all 0.3s ease-out;
  opacity: var(--active);
}

.nav {
  color: white;
  font-size: 5rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  z-index: 2;
  cursor: pointer;
  user-select: none;
  background: unset;
  border: unset;
}

.nav.left {
  transform: translateX(-100%) translateY(-50%);
}

.nav.right {
  right: 0;
  transform: translateX(100%) translateY(-50%);
}


/* Transitions */

.react-flow__node {
    transition: background-color 200ms ease-in;
}
.react-flow__panel.react-flow__attribution.bottom.right {
    display: none;
}

/* Career Tree Tooltip Styling */

.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom {
	background-color: #FFFFFF;
	color: #525C6D;
	/* opacity: 0.8 !important; */
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
  border: none !important;
}

@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Poppins', sans-serif;
}

body {
	margin: 0;
	padding: 0;
}

/* width */
::-webkit-scrollbar {
	transition: all 250ms ease;
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
	transition: all 250ms ease;
}

/* Handle */
::-webkit-scrollbar-thumb {
	transition: all 250ms ease;
	background: #0000001F;
	border-radius: 4px;
	opacity: 0;
}

/* Horizontal scrollbars */
::-webkit-scrollbar:horizontal {
	transition: all 250ms ease;
  height: 7px;
}

::-webkit-scrollbar-thumb:horizontal {
	transition: all 250ms ease;
	background: #00000011;
	border-radius: 4px;
	opacity: 0;
  width: 13px;
}


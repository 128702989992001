@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
.ball {
	animation: bounce 0.4s cubic-bezier(.5,.05,1,.5);
	animation-direction:alternate;
	animation-iteration-count:infinite;
}

@keyframes bounce {
  from { transform:translate3d(0px,8px,0px);}
  to { transform:translate3d(0px,0px,0px);}
} 
